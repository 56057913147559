import React from "react";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";

const Contact = () => {
  return (
    <section className="py-16 lg:section" id="contact">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          {/* text */}
          <motion.div
            variants={fadeIn("right", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex justify-start items-center"
          >
            <div>
              <h4 className="text-x1 uppercase text-accent font-medium mb-2 tracking-wide">
                Formulaire
              </h4>
              <h2 className="text-[45px] lg:text-[90px] lg:mb-40 leading-none mb-12">
                Contactez <br /> Moi
              </h2>
            </div>
          </motion.div>
          {/* form */}
          <motion.form
            action="https://formspree.io/f/mrgnynyw"
            method="POST"
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 border rounded-2xl flex flex-col gap-y-6 mb-24 pb-24 p-6 items-center"
          >
            <input
              className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all"
              type="text"
              placeholder="Votre Nom"
              name="name"
            />
            <input
              className="bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all"
              type="text"
              placeholder="Votre email"
              name="_replyto"
            />
            <textarea
              className="bg-transparent border-b py-12 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12"
              placeholder="Votre message"
              name="message"
            ></textarea>
            <button type="submit" className="btn btn-lg">
              Envoyer
            </button>
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;

import React, { useRef } from "react";
// components
import About from "./components/About";
import Banner from "./components/Banner";
import Contact from "./components/Contact";
import Nav from "./components/Nav";
import Services from "./components/Services";
import Work from "./components/Work";

const App = () => {
  const contactRef = useRef(null);

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="bg-site bg-no-repeat bg-cover overflow-hidden">
      {/* <Header /> */}
      <Banner scrollToContact={scrollToContact} />
      <Nav />
      <About scrollToContact={scrollToContact} />
      <Services />
      <Work />
      <div ref={contactRef}>
        <Contact />
      </div>
      {/* <div className="h-[4000px]"></div> */}
    </div>
  );
};

export default App;

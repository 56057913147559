import React, { useState } from "react";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";
// img
import { FaGithub } from "react-icons/fa";
import Modal from "react-modal";
import Huguette from "../assets/huguette.mp4";
import Img1 from "../assets/portfolio-img1.png";
import Img2 from "../assets/portfolio-img2.png";
import Img3 from "../assets/portfolio-img3.png";

const Work = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <section className="section" id="work">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-x-10">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="lg:flex-1 flex flex-col gap-y-7 mb-16 lg:mb-0 justify-end"
          >
            {/* text */}
            <div className="">
              <h2 className="h2 leading-tight text-accent">
                Mes Derniers <br />
                Projets
              </h2>
              <p className="max-w-sm mb-16">
                Voici une selection de mes derniers projets réalisés cette
                année.
              </p>
              <div className="flex text-[3rem] max-w-max mx-auto lg:mx-0 items-center justify-center">
                <a
                  href="https://github.com/tizen21"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub />
                </a>
              </div>
            </div>
            {/* image */}
            <div
              className="group relative overflow-hidden border-2 border-white/50 rounded-xl hover:cursor-pointer"
              onClick={openModal}
            >
              {/* overlay */}
              <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
              {/* img */}
              <img
                className="group-hover:scale-125 transition-all duration-500"
                src={Img1}
                alt=""
              />
              {/* pretitle */}
              <div className="absolute -top-full left-12 group-hover:top-8  md:group-hover:top-14 transition-all duration-500 z-50">
                <span className="text-gradient">App Mobile / React Native</span>
              </div>
              {/* title */}
              <div className="absolute -bottom-full left-12 group-hover:bottom-14  md:group-hover:bottom-24 transition-all duration-700 z-50">
                <span className="text-3xl text-white">Huguette</span>
              </div>
              {/* Description */}
              <div className="absolute -bottom-full left-12 group-hover:bottom-6 md:group-hover:bottom-14 transition-all duration-700 z-50">
                <span className="text-md md:text-xl text-white">
                  Application mobile de VTC pour femme
                </span>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="lg:flex-1 flex flex-col gap-y-14"
          >
            <a
              href="https://mesfilms-frontend.vercel.app/"
              target="_blank"
              rel="noreferrer"
            >
              {/* image */}
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl hover:cursor-pointer">
                {/* overlay */}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img2}
                  alt=""
                />
                {/* pretitle */}
                <div className="absolute -top-full left-12 group-hover:top-8  md:group-hover:top-14 transition-all duration-500 z-50">
                  <span className="text-gradient">React / Tailwind</span>
                </div>
                {/* title */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-14  md:group-hover:bottom-24 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">My Movies</span>
                </div>
                {/* Description */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-6 md:group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-md md:text-xl text-white">
                    Site qui répertorie les films
                  </span>
                </div>
              </div>
            </a>
            <a
              href="https://reactjourney-2.vercel.app/?filter="
              target="_blank"
              rel="noreferrer"
            >
              {/* image */}
              <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl hover:cursor-pointer">
                {/* overlay */}
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img3}
                  alt=""
                />
                {/* pretitle */}
                <div className="absolute -top-full left-12 group-hover:top-8  md:group-hover:top-14 transition-all duration-500 z-50">
                  <span className="text-gradient">React / Tailwind</span>
                </div>
                {/* title */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-14  md:group-hover:bottom-24 transition-all duration-700 z-50">
                  <span className="text-3xl text-white">React Journey</span>
                </div>
                {/* Description */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-6 md:group-hover:bottom-14 transition-all duration-700 z-50">
                  <span className="text-md md:text-xl text-white">
                    Site sur les concepts de React
                  </span>
                </div>
              </div>
            </a>
          </motion.div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content fixed inset-0 flex items-center justify-center z-50  bg-black bg-opacity-50">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-white text-6xl z-50"
          >
            &times;
          </button>

          <div className="aspect-w-16 aspect-h-9 md:max-w-2xl flex items-center justify-center">
            <video
              autoPlay
              controls
              className="w-1/2 h-full object-cover rounded-2xl shadow-xl"
              playsInline
            >
              <source src={Huguette} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default Work;

import React from "react";
// type Animation
import { TypeAnimation } from "react-type-animation";
// images
import Image from "../assets/avatar.svg";
// icons
import { FaGithub, FaLinkedin } from "react-icons/fa";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";

const Banner = ({ scrollToContact }) => {
  return (
    <section
      className="min-h-[100vh] lg:min-h-[100vh] flex items-center"
      id="home"
    >
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12">
          {/* text */}
          <div className="flex-1 text-center font-secondary lg:text-left">
            <motion.h1
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="text-[55px] font-bold leading-[0.8] lg:text-[110px]"
            >
              ANTHONY <span>REY</span>
            </motion.h1>
            <motion.div
              variants={fadeIn("up", 0.4)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-6 text-[36px] lg-text-[60px] font-secondary font-semibold uppercase leading-[1]"
            >
              <span className="text-white mr-4 ">Je suis un</span>
              <TypeAnimation
                sequence={[
                  "Developeur Web",
                  2000,
                  "Commercial",
                  2000,
                  "Passionné",
                  2000,
                ]}
                speed={50}
                className="text-accent"
                wrapper="span"
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn("up", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="mb-8 max-w-lg mx-auto lg:mx-0"
            >
              Bienvenue sur mon Portfolio
            </motion.p>
            <motion.div
              variants={fadeIn("up", 0.6)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0"
            >
              <button className="btn btn-lg" onClick={scrollToContact}>
                Contactez moi
              </button>

              <a
                href="https://drive.google.com/file/d/1JUYoQiLBHeAZ--5Ht6sKvl03vDrg5-ee/view?usp=sharing"
                download="CV_Anthony_REY.pdf"
                target="_blank"
                className="text-gradient btn-link"
                rel="noreferrer"
              >
                Telechargez mon CV
              </a>
            </motion.div>
            {/* socials */}
            <motion.div
              variants={fadeIn("up", 0.7)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
              className="flex text-[30px] gap-x-6 max-w-max mx-auto lg:mx-0 justify-between"
            >
              <a
                href="https://www.linkedin.com/in/anthony-rey-16108695/"
                target="_blank"
                rel="noreferrer"
                aria-label="Profil LinkedIn d'Anthony Rey"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://github.com/tizen21"
                target="_blank"
                rel="noreferrer"
                aria-label="Profil GitHub d'Anthony Rey"
              >
                <FaGithub />
              </a>
              {/* <a
                href="https://www.instagram.com/tizen95/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram />
              </a> */}
            </motion.div>
          </div>
          {/* image */}
          <motion.div
            variants={fadeIn("down", 0.5)}
            initial="hidden"
            whileInView={"show"}
            className="hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]"
          >
            <img src={Image} alt="Avatar d'Anthony Rey" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

import React from "react";
// countup
import CountUp from "react-countup";
// intersection observer hook
import { useInView } from "react-intersection-observer";
// motion
import { motion } from "framer-motion";
//variant
import { fadeIn } from "../variants";

const About = ({ scrollToContact }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className="section" id="about" ref={ref}>
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen">
          {/* img */}
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 bg-about bg-contain  bg-no-repeat h-[640px] mix-blend-lighten bg-top lg:mb-24"
          ></motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn("left", 0.5)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 text-center"
          >
            <h2 className="h2 text-accent">A propos de moi.</h2>
            <h3 className="h3 mb-4">34 ans / Paris 🇫🇷</h3>
            <p className="mb-6">
              Après 15 années à évoluer dans le domaine commercial et
              entreprenarial, j'ai souhaité donner une nouvelle dimension à ma
              carrière professionnelle en suivant une formation intensive en
              développement web et applications fullstack. Mon objectif est de
              réussir ma reconversion dans ce secteur. J'ai toujours été
              passionné par la Tech. Mon parcours varié m'a permis d'acquérir
              une solide expertise dans deux domaines complémentaires, alliant
              compétences commerciales et techniques informatiques. Aujourd'hui,
              je suis à la recherche d'un stage de 6 mois pour continuer ma
              montée en compétences.
            </p>
            {/* stats */}
            <div className="flex gap-x-6 lg:gap-x-10 mb-6 justify-center">
              <div>
                <div className="text-[34px] font-tertiary text-gradient mb-2">
                  {inView ? <CountUp start={0} end={4} duration={3} /> : null}
                  M+
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  De C.A <br />
                  Rapporté
                </div>
              </div>
              <div>
                <div className="text-[34px] font-tertiary text-gradient mb-2">
                  {inView ? <CountUp start={0} end={15} duration={3} /> : null}
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Ans <br />
                  D'XP Pro
                </div>
              </div>
              <div>
                <div className="text-[34px] font-tertiary text-gradient mb-2">
                  {inView ? (
                    <CountUp start={0} end={1000} duration={3} />
                  ) : null}
                  +
                </div>
                <div className="font-primary text-sm tracking-[2px]">
                  Clients <br />
                  Satisfaits
                </div>
              </div>
            </div>
            <div className="flex gap-x-8 items-center justify-center">
              <button className="btn btn-lg" onClick={scrollToContact}>
                Contactez moi
              </button>
              {/* <a href="#" className="text-gradient btn-link">
                Mon Portfolio
              </a> */}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
